





@media (min-width: 290px) and (max-width: 450px) { /* Стили для устройств с маленьким экраном, размерами 768px - 991px (планшеты и подобные) */
  .obv{
    max-height: 250px;
    height: 150%;
  }
  .cardimg{
    width: '100px';
    height: '100px';
    margin-top: 0px;
    border-radius: 4px;
  }
  .bt_post{
    font-size: '5px';
  }
  .postbtn{
    margin-top: '-30px';
  }
  .txt7cur{
    font-size: 10px;
  }
  .destext{

    overflow: scroll;
        text-overflow: ellipsis;
        display: -moz-box;
        -moz-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        line-clamp: 3;
        box-orient: vertical;
        max-height: 45px;
        text-overflow: clip;
        margin-top: -5px;
  }
  .location_text{
    font-size: 10px;
  }
  .bt_call{
   display: block;
   font-size: 10px;
   width: 50px;
   height: 10px;
   text-align: center;
   margin-top: -5px;
  }
  .bt_call2{
   display: none;
  }
  .meddate{
    display: none;
  }
  .medname{
   display: none;
  }
  .bt_call{
    display: none;
  }
  .sms2{
    font-size: 18px;

  }
  .carousel2{
    height: auto;
    line-height: 160px;
    background-color: aqua;
    }
    .podcat{
      margin-top: 0px;
    }
  .carimg{
    width: 100%;
    height: 130px;
  }
  .heart{
    font-size: 18px;

  }
  .currency{
    font-size: 10px;
  }
  .location_text{
    display: none;
  }
  .medbody{
    padding: 5px;
    top: 0;
    margin: 0;
    display: none;
  }
  .medbody2{
    padding: 5px;
    top: 0;
    margin: 0;
    display: block;
  }
  .body{
  background-image: url(https://img5.lalafo.com/i/banners/3c/fe/52/a9307089265a88548a8f29e267.png);
  background-position-x: center;
  padding: 0;
  background-attachment: fixed;
  z-index: -1; 
  width: 100%;
  margin-top: -70px;
  }
  .fix{
    position: fixed;
    top: 0px;
    z-index: 1;
    width: 100%;
  }
  .birimdikdiv{
    width: 250px;
  }
  .medcategory2{
    display: block;
    margin: 10%;
    width: 100px;
  }
  .medsearch{
    display: block;
    margin-top: 15%;
  }
.navpost{
  display: none;
}
.ds{
  display: block;
}
.none-max-600{
  display: none;
}
.block-max-600{
  display: block;
}
.des{
  display: none;
}
.postimage{
  display: flex;
  width: 100%;
  flex-direction: column;
  /* margin-top: -11px; */
}
.postimage2{
  display: none;
}
.postsms{
  float: right;
}
.imgnone{
  display: none;
  
}
.userimg3{
  display: block;
  
  
}
.userimg4{
display: none;

}
.txt7med{
  color:rgb(10, 19, 49);
  font-family: 'd';
  font-size: 26px;
  font-stretch: 100%;
  font-style: normal,inherit;
  font-weight: 700;
  line-height: 24px;
  vertical-align: baseline;
  white-space: nowrap;
  margin-top: -5px;
}
.txt7{
  color:rgb(10, 19, 49);
  font-family: 'Ubuntu', sans-serif,inherit;
  font-size: 16px;
  font-stretch: 100%;
  font-style: normal,inherit;
  font-weight: 700;
  line-height: 24px;
  vertical-align: baseline;
  white-space: nowrap;
  margin-top: -5px;
}
.postcardimg{
  width: 100%;
  height: 120px;
}
.txt77{
  color: black;
  font-family: 'Kdam Thmor Pro', sans-serif;
  font-size: 12px;
  margin-top: -30px;
}
.cardtext{
  font-size: 12px;
  margin-top: -10px;
  color: gray;
}
.carduserimg2{
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.datetxt{
  font-size: 12px;
  float: right;
}
.market{
  font-size: 30px;
  color: #ffffff ;
  font-family: 'Cinzel', serif;
  position: relative;
}
.mobavatar{
  margin-top: 100px;
}
.reg{
  display: block;
}
.rek_left {
  display: none;
}
.rek_right {
  display: none;
}
.search1{
  display: none;
}
.body{
  width: 400px;
  height: 100%
}
.bg-light{
  margin-top: 0%;
  width: 400px;
}
.birimdikdiv2{
  width: 100%;
}
.postcontainer{
  margin-top: 0;
}
.card-block1{
  width: 100%;
  height: 12px;
}
.none-400{
  display: none;
}
.txt-400{
  font-size: 12px;
  width: 95%;
  overflow: hidden;
  -webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.txt-caategoryname-400{
  font-size: 14px;
}
.postbtn2{
  display: none;
}
.postbtn3{
  display: none;
}
.postbtn4{
  display: none;
}
.ant-image-mas{
  display: none;
}
.vhod{
  height: 550px;
  margin-top: 20px
}
.foot_mob{
  z-index: 1;
  position:fixed;
   left:0px;
   bottom:1px;
   height: 70px;
   width:100%;
}
.wrapper {
  display: table;
  height: 100%;
}
.content {
  display: table-row;
  height: 100%;
}
.vhodmob{
  margin-left: 100px;
}
.podat{
  font-size: 14px;
  color: white;
  float: right;
}
.navmobtext{
  font-size: 12px;
  color: white;
}
.nav_pad{
  padding: 0px;
  margin-left: 12px;
}
.navimg{
display: none;
}
.navimgmob{
display: block;
}
.post2{
display: none;
}
.carousel{
  display: block;
}
.filtr{
  margin-top: 30%;
}
.mainpost2_mob{
  margin-left: 20px;
}
  
  }















































  


















    @media (min-width: 400px) and (max-width: 599px){
      .txt-400{
        font-size: 12px;
        width: 95%;
        overflow: hidden;
        -webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
      }
      .body{
        background-image: url(https://img5.lalafo.com/i/banners/3c/fe/52/a9307089265a88548a8f29e267.png);
        background-position-x: center;
        padding: 0;
        background-attachment: fixed;
        z-index: -1; 
        width: 100%;
        margin-top: -52px;
        }
        .bt_post{
          font-size: '5px';
        }
        .cardimg{
          width: '100px';
          height: '100px';
          margin-top: 0px;
          border-radius: 4px;
        }
        .carimg{
          width: 100%;
          height: 120px;
        }
        .rek_left {
          display: none;
        }
        .rek_right {
          display: none;
        }
        .podcat{
          display: block;
        }
        .maincon{
          width: 100%;
        }
        .nav_pad{
          padding: 0px;
          margin-left: 12px;

        }
        .postimage{
          display: flex;
          flex-direction: column;
        }
        .postimage2{
          display: none;
        }
        .userimg3{
          display: none;
        }
        .userimg4{
          display: block;
          padding: 0px;
          
        }
        .meddate{
          display: none;
        }
        .postsms{
          margin-top: 0px;
          float: right;
        }
        .userimg5{
          display: block;
        }
        .postbtn2{
          display: none;
        }
        .postbtn3{
          display: none;
        }
        .postbtn4{
          display: none;
        }
        .mainrow{
          width: 110%;
        }
        .postcardimg{
          width: 100%;
          height: 100%;
        }
        .carduserimg2{
          width: 30px;
          height: 30px;
        }
        .postrow{
          display: none;
        }
        .info{
          display: none;
        }
        .desc{
          display: none;
        }
        .city{
          margin-top: 10px;
          display: none;
        }
        .currency2{
          display: none;
        }
        .view{
          display: none;
        }
        .like{
          display: none;
        }
        .btncallsms{
          display: none;
        }
        .chatscroll{
          overflow: scroll;
          height: 420px;
        }
        .vhod{
          height: 550px;
          margin-top: 0px
        }
        .foot_mob{
          z-index: 1;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 60px;
          position: fixed;
          
        }
        .categorymain{
          margin-top: 10%;
        }
        .vhodmob{
          margin-left: 100px;
        }
        .medbody2{
          display: none;
        }
        .location_text{
          display: block;

        }
        .txt7{
          color:rgb(10, 19, 49);
          font-family: 'Pacifico',;
          font-size: 16px;
          font-stretch: 100%;
          font-style: normal,inherit;
          font-weight: 700;
          line-height: 24px;
          vertical-align: baseline;
          white-space: nowrap;
          margin-top: 10px;
      }
      .destext{
        overflow: hidden;
            text-overflow: ellipsis;
            display: -moz-box;
            -moz-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            line-clamp: 3;
            box-orient: vertical;
            max-height: 52px;
            text-overflow: clip;
            margin-top: -5px;
            width: 115%;
            margin-left: -12px;
      }
      .podat{
        font-size: 10px;
        color: white;
      }
      .navmobtext{
        font-size: 12px;
        color: white;
      }
      .podat{
        font-size: 12px;
        color: white;
        float: right;
      }
      .navimg{
        display: none;
        }
      .navimgmob{
        display: block;
      }
      .post2{
      display: none;
      }
      .medsearch{
        display: block;
        margin-top: 10%;
      }
      .medcategory2{
        display: block;
        margin: 10%;
        width: 100px;
      }
      .ant-image-preview-img{
        margin-top: -160px;
      }
      .mainpost2_mob{
        margin-left: 40px;
      }
      .pokazat{
        bottom: 65px;
      }
    }






    @media (min-width: 600px) and (max-width: 700px) {
      .medcategory2{
        display: block;
        margin: 10%;
        width: 100px;
      }
      .maincon{
        width: 100%;
      }
      .bt_call{
       display: none;
      }
      .carimg{
        width: 100%;
        height: 140px;
      }
      .search2{
        display: none;
      }
      .ds{
        display: none;
      }
      .block-max-600{
        display: none;
  
      }
      .cardimg{
        width: '200px';
        height: '200px';
        margin-top: 0px;
        border-radius: 4px;
      }
      .postbtn{
        margin-top: -20px;
      }
      .postimage{
        display: none;
      }
      .postimage2{
        display: flex;
        flex-direction: column;
      }
      .margin10{
        margin-top: -10px;
      }
      .userimg{
        font-size: 26px;
        color: grey;
      }
      
      .postsms{
        float: right;
        margin-top: -35px;
  
      }
      .none-max-600{
        display: block;
        width: '100%';
        height: '180px';
        border-Top-Left-Radius: '5px';
        border-Top-Right-Radius: '5px';
      }
      .imgnone{
        display: block;
      }
      .userimg3{
        display: none;
  
      }
      .userimg4{
      display: block;
  
      }
      .txt7{
        color:rgb(10, 19, 49);
        font-family: 'Ubuntu', sans-serif,inherit;
        font-size: 16px;
        font-stretch: 100%;
        font-style: normal,inherit;
        font-weight: 700;
        line-height: 24px;
        vertical-align: baseline;
        white-space: nowrap;
        margin-top: 30px;
    }
    .txt77{
      color: black;
      font-family: 'Kdam Thmor Pro', sans-serif;
      font-size: 14px;
    }
    .carduserimg2{
      width: 35px;
      height: 35px;
      border-radius: 50%;
    }
    .datetxt{
      font-size: 16px;
    }
    .reg{
     display: none;
    }
    .postcardimg{
      width: 100%;
      height: 150px;
    }
    .cardimg3{
      width: 100%;
      height: 180px;
    }
    .rek_right{
      display: none;
    }
    .rek_left{
      display: none;
    }
    .userimg5{
      display: none;
    }
    .postbtn2{
      display: none;
    }
    .postbtn3{
      display: none;
    }
    .postbtn4{
      display: none;
    }
    .categorymain{
      margin-top: 0px;
    }
    .medbody2{
      display: none;
    }
    .destext{
      overflow: hidden;
          text-overflow: ellipsis;
          display: -moz-box;
          -moz-box-orient: vertical;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          line-clamp: 3;
          box-orient: vertical;
          max-height: 52px;
          text-overflow: clip;
          margin-top: -5px;
          width: 115%;
          margin-left: -12px;
    }
    .rek_left{
      display: none;
    }
    .navmobtext{
      font-size: 12px;
      color: white;
    }
    .podat{
      font-size: 12px;
      color: white;
      float: right;
    }
    .navimg{
    display: none;
    }
    .navimgmob{
    display: block;
    }
    .foot_mob{
      z-index: 1;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 60px;
      position: fixed;
    }
    .post2{
      display: none;
      }
      .medsearch{
        display: block;
        margin-top: 10%;
      }
      .mainrow{
        width: 110%;
      }
      }
















    @media (min-width: 701px) and (max-width: 899px){
      .formcard{
        width: 100%;
      }
      .row_realty{
        width: 150%;
      }
      .carimg{
        width: 100%;
        height: 150px;
      }
      .postrow{
        display: none;
      }
      .search2{
        display: none;
      }
      .medbody2{
        display: none;
      }
      .destext{
        overflow: hidden;
            text-overflow: ellipsis;
            display: -moz-box;
            -moz-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            line-clamp: 3;
            box-orient: vertical;
            max-height: 65px;
            text-overflow: clip;
            margin-top: -5px;
      }
      .post2{
        display: none;
      }
      .post1{
        display: flex;
      }
      .postimage{
        display: none;
      }
      .txt7{
        margin-top: 20px;
      }
      .rek_left{
        display: none;
      }
      .rek_right{
        display: none;
      }
      .navmobtext{
        font-size: 12px;
        color: white;
      }
      .podat{
        font-size: 12px;
        color: white;
        float: right;
      }
      .postcardimg{
        width: 100%;
        height: 120px;
      }
      .carduserimg2{
        width: 25px;
        height: 25px;
        border-radius: 50%;
      }
      .txt7{
        color:rgb(10, 19, 49);
        font-family: 'Pacifico',;
        font-size: 16px;
        font-stretch: 100%;
        font-style: normal,inherit;
        font-weight: 700;
        line-height: 24px;
        vertical-align: baseline;
        white-space: nowrap;
        margin-top: 10px;
    }
    .navimg{
  display: none;
  }
  .navimgmob{
    display: block;
    }
    .foot_mob{
      z-index: 1;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 60px;
      position: fixed;
    }
    .medsearch{
      display: block;
      margin-top: 8%;
    }
    .postimage2{
      display: flex;
      flex-direction: column;
    }
    .obv{
      min-width: 100%;
    }

    }







    @media (min-width: 900px) and (max-width: 1023px){
      .row_realty{
        width: 150%;
      }
      .bg-light{
        width: 150%;
      }
      .rek_left{
        display: none;
      }
      .rek_right{
        display: none;
      }
      .carimg{
        width: 100%;
        height: 170px;
      }
      .postrow{
        display: none;
      }
      .search2{
        display: none;
      }
      .medbody2{
        display: none;
      }
      .destext{
        overflow: hidden;
            text-overflow: ellipsis;
            display: -moz-box;
            -moz-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            line-clamp: 3;
            box-orient: vertical;
            max-height: 65px;
            text-overflow: clip;
            margin-top: -5px;
      }
      .postimage2{
        display: none;
      }
        .postrow{
          display: none;
        }
        .search2{
          display: none;
        }
        .destext{
          overflow: hidden;
              text-overflow: ellipsis;
              display: -moz-box;
              -moz-box-orient: vertical;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              line-clamp: 3;
              box-orient: vertical;
              max-height: 65px;
              text-overflow: clip;
              margin-top: -5px;
        }
        .destext2{
          overflow: hidden;
              text-overflow: ellipsis;
              display: -moz-box;
              -moz-box-orient: vertical;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              line-clamp: 3;
              box-orient: vertical;
              max-height: 40px;
              text-overflow: clip;
              margin-top: -5px;
        }
        .post2{
          display: none;
        }
        .post1{
          display: block;
        }
        .navmobtext{
          font-size: 12px;
          color: white;
        }
        .podat{
          font-size: 12px;
          color: white;
          float: right;
        }
        .postcardimg{
          width: 100%;
          height: 120px;
        }
        .carduserimg2{
          width: 25px;
          height: 25px;
          border-radius: 50%;
        }
        .txt7{
          color:rgb(10, 19, 49);
          font-family: 'Pacifico',;
          font-size: 16px;
          font-stretch: 100%;
          font-style: normal,inherit;
          font-weight: 700;
          line-height: 24px;
          vertical-align: baseline;
          white-space: nowrap;
          margin-top: 10px;
      }
      .navimg{
        display: block;
        }
        .navimgmob{
          display: none;
          }
          .foot_mob{
            z-index: 1;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 60px;
            position: fixed;
          }
          .medsearch{
            display: block;
            margin-top: 10%;
          }
          .postimage{
            display: flex;
            flex-direction: column;
          }

      }
    





      @media (min-width: 1024px) and (max-width: 1100px){
        .carimg{
          width: 100%;
          height: 180px;
        }
        .navmobtext{
          font-size: 12px;
          color: white;
        }
        .podat{
          font-size: 12px;
          color: white;
          float: right;
        }
        .postimage{
          display: none;
        }
        .postcardimg{
          width: 100%;
          height: 120px;
        }
        .carduserimg2{
          width: 25px;
          height: 25px;
          border-radius: 50%;
        }
        .txt7{
          color:rgb(10, 19, 49);
          /* font-family: 'Pacifico',; */
          font-size: 16px;
          font-stretch: 100%;
          font-style: normal,inherit;
          font-weight: 700;
          line-height: 24px;
          vertical-align: baseline;
          white-space: nowrap;
          margin-top: 10px;
      }
      .navimg{
        display: block;
        }
        .navimgmob{
          display: none;
          }
          .post1{
            display: block;
           }
           .post2{
            display: none;
           }
           .medsearch{
            display: block;
            margin-top: 10%;
          }
          .postimage2{
            display: flex;
            flex-direction: column;
            /* pointer-events: none; */
          }
          .ant-image-preview-img{
            width: 40%;
            height: 50%;
            margin-left: 400px;
            margin-top: -200px;
          }
      }





      @media (min-width: 1100px) {
        .carimg{
          width: 100%;
          height: 240px;
        }
        .navmobtext{
          font-size: 12px;
          color: white;
        }
        .podat{
          font-size: 12px;
          color: white;
          float: right;
        }
        .postimage{
          display: none;
        }
        .postcardimg{
          width: 100%;
          height: 120px;
        }
        .carduserimg2{
          width: 25px;
          height: 25px;
          border-radius: 50%;
        }
        .txt7{
          color:rgb(10, 19, 49);
          /* font-family: 'Pacifico',; */
          font-size: 22px;
          font-stretch: 100%;
          font-style: normal,inherit;
          font-weight: 700;
          line-height: 24px;
          vertical-align: baseline;
          white-space: nowrap;
          margin-top: 10px;
      }
      .txt8{
        color:rgb(10, 19, 49);
        font-family: 'Pacifico',;
        font-size: 22px;
        font-stretch: 100%;
        font-style: normal,inherit;
        font-weight: 700;
        line-height: 24px;
        vertical-align: baseline;
        white-space: nowrap;
        margin-top: 10px;
    }
    .navimg{
      display: block;
      }
  .navimgmob{
    display: none;
    }
    .post1{
      display: none;
    }
    .postimage2{
      display: flex;
      flex-direction: column;
    }
    .medsearch{
      display: block;
      margin-top: 5%;
    }
    .ant-image-preview-img{
      width: 40%;
      height: 50%;
      margin-left: 400px;
      margin-top: -200px;
    }
    .nav_pad{
      padding: 0px;
      width: 100%;
      margin-left: 12px;
    }
    .destext2{
      overflow: hidden;
          text-overflow: ellipsis;
          display: -moz-box;
          -moz-box-orient: vertical;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          line-clamp: 2;
          box-orient: vertical;
          max-height: 62px;
          text-overflow: clip;
          margin-top: -5px;
    }
    .destext{
      overflow: hidden;
          text-overflow: ellipsis;
          display: -moz-box;
          -moz-box-orient: vertical;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          line-clamp: 3;
          box-orient: vertical;
          max-height: 69px;
          text-overflow: clip;
          margin-top: -5px;
    }
    .obv{
      width: 90%;
    }
      }
    