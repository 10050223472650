*{
  box-sizing: border-box;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:wght@700&family=Roboto+Mono:ital,wght@1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:wght@700&family=Merriweather+Sans:ital,wght@1,500&family=Roboto+Mono:ital,wght@1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kdam+Thmor+Pro&display=swap');

/* В вашем CSS-файле */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

@font-face {
  font-family: "Dangrek-Regular";
  src: local("Dangrek-Regular"),
   url('./fonts/Dangrek-Regular.ttf') format("truetype");
  font-weight: normal;
  }
  /* Общие стили */
  

  
.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f4f7f6;
}

.register-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 400px;
  text-align: center;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.input-field {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #dcdcdc;
  font-size: 14px;
}

.form-button {
  width: 100%;
  padding: 12px;
  background-color: #4CAF50;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-button:hover {
  background-color: #45a049;
}

.form__show {
  display: block;
}

.form__hide {
  display: none;
}

.google-login {
  margin-top: 20px;
  width: 100%;
}

.google-login .google-button {
  width: 100%;
  background-color: #4285F4;
  color: white;
  padding: 12px;
  border-radius: 4px;
  border: none;
}

.google-login .google-button:hover {
  background-color: #357ae8;
}

.body{
  font-family: "Roboto", sans-serif;
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgb(14, 25, 119) 100%);
  /* background-image: url(https://img5.lalafo.com/i/banners/3c/fe/52/a9307089265a88548a8f29e267.png); */
  background-position-x: center;
  padding-left: 2.5%;
  padding-right: 2.5%;
  background-attachment: fixed;
  z-index: -1; 
  width: 100%;
  
  /* background-color: lawngreen */
}
.header {
    background: linear-gradient(90deg, rgba(2,0,36,1) 23%, rgba(9,77,121,1) 52%, rgba(0,212,255,1) 100%);
    color: grey;
    
    height: 220px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}
.mainbg{
margin-top: 80px;
position: sticky;
top: 1rem; 
}
.clip {
  white-space: nowrap; /* Запрещаем перенос строк */
  overflow: hidden; /* Обрезаем все, что не помещается в область */
  padding: 5px; /* Поля вокруг текста */
  text-overflow: ellipsis; /* Добавляем многоточие */
 }
.hh:hover{
  background-color: #8c8e9157;
}
.hh2:hover{
  background-color: #8c8e9157;
}
.sms1:hover{
  color: #22ca46;
}
.sms2:hover{
  color: #22ca46;
}
.heart:hover{
  color: rgb(255, 49, 100);
}
.heart2:hover{
  color: rgb(255, 49, 100);
}
.heart1:hover{
  color: rgb(255, 49, 100);
}

.navscroll{
  display: flex;
  align-items: center;
  overflow-y: scroll;
}
.navt{
  font-size: 12px;
  font-family: 'Times New Roman', Times, serif;
  color: rgb(10, 10, 10);
}
.navbg{
  border-radius: 15px;

  width: 700px;
}
.hdtxt{
    font-size: 16px;
    font-family: 'Times New Roman', Times, serif;
    color: white;
    margin-left: -40px;
}
.hdtxt2{
    font-size: 16px;
    font-family: 'Times New Roman', Times, serif;
    color: white;
    margin-left: -10px;
}
.footertxt{
    color: rgba(255, 255, 255, 0.938);
}
.hdtxt3{
    font-size: 18px;
    font-family: 'Times New Roman', Times, serif;
    color: rgba(100, 100, 100, 0.945);

}
.hdtxt33{
  font-size: 24px;
  font-family: 'Times New Roman', Times, serif;
  color: rgba(100, 100, 100, 0.945);

}
.hdtxt333{
  font-size: 24px;
  font-family: 'Times New Roman', Times, serif;
  color: rgba(38, 190, 0, 0.945);

}
.heart{
  color: grey;
  font-size: 24px;
  margin-left: 10px;

}
.user1{
  color: grey;
  font-size: 30px;
  margin-left: 40px;
}
.btn1{
  border-radius: 15px;
  background-color: rgb(255, 49, 100);
  color: aliceblue;
  font-size: 20px;
  margin-left: 20px;
  
}
.cont1{
  background-color: #474B4F;
  height: 150px;
}
.tx1{
  color: white;
  font-size: 16px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.sms1{
  color: rgb(255, 255, 255);
  font-size: 34px;
  margin-left: 20px;
}
.text{
    color: grey;
}
.text2{
    font-size: 22px;
    font-family: 'Times New Roman', Times, serif;

}
.text3{
    font-size: 22px;
    font-family: 'Times New Roman', Times, serif;
    border: 2px solid ;
    border-radius: 15px;
    border-color: blueviolet;
    color: rgb(101, 5, 190);
}
.text44{
    width: 100%;
    height: 20%;
    /* white-space: nowrap; */
    overflow: hidden;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(62, 62, 62);
}
.carduserimg{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-top: -15px;
}


.sms2{
  font-size: 24px;
  color: grey;
}
.btbt{
  width: 100%;
}
.btcall{
  border-color: red;
}
.heart2{
  font-size: 20px;
  margin-left: 10px;
  color: red;
}
.heart1{
  font-size: 32px;
  margin-left: 10px;
  color: rgb(255, 255, 255);
}
.izbrannyi{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.txt5{
    color: black;
    font-family: 'Times New Roman', Times, serif;

}
.txt6{
    color: black;
    font-family: 'Times New Roman', Times, serif;
    font-size: 18px;
    float: right;
}
.navtxt{
    color: beige;
    font-size: 22px;
    font-family: 'Times New Roman', Times, serif;
}
.kortxt2{
    font-family: 'Times New Roman', Times, serif;
}
.kg{
    border-radius: 50%;
    width: 30px;
    height: 30px;
    color: grey;
}
.reg{
    float: right;
    color: white;
}
.menu{
    color: rgb(99, 100, 99);
    font-size: 30px;
    position: relative;
    margin-top: 20px;
}
.market{
  
    font-size: 40px;
    color: #ffffff ;
    font-family: 'Cinzel', serif;
    position: relative;
}
.div2{
  width: 100px;
  height: 100px;
  
}
.dostavka{
    color: white;
    font-size: 50px;
    position: relative;
    margin-left: -40px;
    margin-top: 20px;
}
.user{
    color: white;
    font-size: 50px;
    position: relative;
    margin-left: px;
    margin-top: 20px;
}
.korz{
    color: white;
    font-size: 50px;
    position: relative;
    margin-left: 30px;
    margin-top: 20px;
}
.carousel{
    width: 100%;
    height: 0;
}
.podcat{
  margin-top: 20px;
}
.img1{
    border-radius: 15px;
    width: 100%;
    height: 200px;
    margin-top: 20px;

}
.img2{
    border-radius: 15px;
    width: 100%;
    height: 200px;
    margin-top: 32px;
    
}
.postimg2{
  width: 100%;
  height: 100%;
  margin-top: 10%;
}
.footerimg{
    width: 100%;
    height: 70px;
}
.bgmainposts{
  background-color: rgb(230, 222, 222);
}
.div{
    background-color: blueviolet;
    border-radius: 50%;
    width: 3px;
    height: 14px;
    margin-left: 50px;
}
.post{
    background-image: url(https://smartcasuals.ru/wp-content/uploads/2019/03/DSC6339-100x100.jpg);
}
.postimg{
    width: 100px;
    height: 100px;
}
.korimg{
    width: 400px;
    height: 400px;
}
.card1{
  display: flex;
}
.imgshare{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.card-block1{
  width: 100%;
  height: 50px;

}
.conn{
  height: 500px;
}
.inline{
  display: inline-flex;
}

.pag{
  width: 100%;
  margin-left: 250px;
}


.nav-scroller {
  overflow-y: hidden;
  background-color: #22ca46;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
.nav-scroller__items {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  white-space: nowrap;
}
.nav-scroller__item {
  color: #424242;
  display: flex;
  padding: 0.5rem 1.25rem;
  text-decoration: none;
}
.nav-scroller__item:not(:last-child) {
  border-right: 1px solid #eee;
}
.nav-scroller__item_active {
  background-color: #fff59d;
}
.nav-scroller__item:not(.nav-scroller__item_active):hover {
  background-color: #f5f5f5;
}
/* для активного пункта */
.nav-scroller__item_active:hover {
  background-color: #fff176;
}
.mmm{
  width: 40px;
  height: 40px;
  background-image: url("http://localhost:3000/images/premium-icon-menu-bar-5461304.png");
  background-position: center;
  background-size: cover;
  background-color: transparent;
  
}
.transport{
  width: 40px;
  height: 40px;
  background-image: url("http://localhost:3000/images/free-icon-car-1032943.png");
  background-position: center;
  background-size: cover;
  background-color: transparent;
}
.nedvij{
  width: 40px;
  height: 40px;
  background-image: url("http://localhost:3000/images/free-icon-wooden-house-2959732.png");
  background-position: center;
  background-size: cover;
  background-color: transparent;
}
.electronics2{
  width: 40px;
  height: 40px;
  background-image: url("http://localhost:3000/images/free-icon-television-465470.png");
  background-position: center;
  background-size: cover;
  background-color: transparent;
}
.works{
  width: 40px;
  height: 40px;
  background-image: url("http://localhost:3000/images/free-icon-suitcase-465435.png");
  background-position: center;
  background-size: cover;
  background-color: transparent;
}
.uslugi{
  width: 40px;
  height: 40px;
  background-image: url("http://localhost:3000/images/free-icon-wrench-462872.png");
  background-position: center;
  background-size: cover;
  background-color: transparent;
}


.gradient-button {
  font-size: 18px;
  text-decoration: none;
  display: inline-block;
  color: rgb(0, 0, 0);
  padding: 3px 15px;
  margin: 5px 5px;
  border-radius: 10px;
  font-family: 'Montserrat', sans-serif;
  /* text-transform: uppercase; */
  letter-spacing: 2px;
  background-image: linear-gradient(to right, #6e6a87 0%, #2d2d7f 51%, #777796 100%);
  background-size: 200% auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, .1);
  transition: .5s;
}
.gradient-button:hover {
  background-position: right center;
}
.navbg1{
  background-color: #ffffff ;
}
.medname{
  margin-top: 10px;
}
.currency{
  font-size: 12px;
  margin-left: 10px;
}
.ow{
  overflow-x: scroll;
}
.ow::-webkit-scrollbar {
  width: 10px;
  
  background-color: transparent;
}
.navtxtcatgeory{
  font-size: 12px;
  color: rgb(0, 0, 0);
}
#components-layout-demo-top-side-2 .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}
/* body {
  background: #2d2d7f !important;
} */
/* main-header start */
[data-target="#mainMenu"] {
  position: relative;
  z-index: 999;
}

#mainMenu li > a {
  font-size: 12px;
  letter-spacing: 1px;
  color: #fff;
  font-weight: 400;
  position: relative;
  z-index: 1;
  text-decoration: none;
}

.main-header.fixed-nav #mainMenu li > a {
  color: #fff;
  text-decoration: none;
}

#mainMenu li:not(:last-of-type) {
  margin-right: 30px;
}

#mainMenu li > a::before {
  position: absolute;
  content: "";
  width: calc(100% - 1px);
  height: 1px;
  background: #fff;
  bottom: -6px;
  left: 0;

  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: right center;
  -ms-transform-origin: right center;
  transform-origin: right center;
  z-index: -1;

  -webkit-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
}

#mainMenu li > a:hover::before,
#mainMenu li > a.active::before {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
}

.main-header.fixed-nav #mainMenu li > a::before {
  background: #000;
}

.main-header {
  position: fixed;
  top: 25px;
  left: 0;
  z-index: 99;
  width: 100%;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.main-header.fixed-nav {
  top: 0;
  background: #fff;
  -webkit-box-shadow: 0 8px 12px -8px rgba(0, 0, 0, 0.09);
  box-shadow: 0 8px 12px -8px rgba(0, 0, 0, 0.09);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.main-header.fixed-nav .navbar-brand > img:last-of-type {
  display: block;
}

.main-header.fixed-nav .navbar-brand > img:first-of-type {
  display: none;
}
.navbar-brand {
  color: #fff;
}
.main-header .navbar-brand img {
  max-width: 40px;
  animation: fadeInLeft 0.4s both 0.4s;
}
/* main-header end */
@media (max-width: 991px) {
  /*header starts*/
  .collapse.in {
    display: block !important;
    padding: 0;
    clear: both;
  }

  .navbar-toggler {
    margin: 0;
    padding: 0;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 25px;
    right: 0;
    border: none;
    border-radius: 0;
    outline: none !important;
  }

  .main-header .navbar {
    float: none;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }

  .main-header .navbar-nav {
    margin-top: 70px;
  }

  .main-header .navbar-nav li .nav-link {
    text-align: center;
    padding: 20px 15px;
    border-radius: 0px;
  }

  /**/
  .main-header .navbar-toggler .icon-bar {
    background-color: #fff;
    margin: 0 auto 6px;
    border-radius: 0;
    width: 30px;
    height: 3px;
    position: absolute;
    right: 0;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  .main-header .navbar .navbar-toggler .icon-bar:first-child {
    margin-top: 3px;
  }

  .main-header .navbar-toggler .icon-bar-1 {
    width: 10px;
    top: 0px;
  }

  .main-header .navbar-toggler .icon-bar-2 {
    width: 16px;
    top: 12px;
  }

  .main-header .navbar-toggler .icon-bar-3 {
    width: 20px;
    top: 21px;
  }

  .main-header .current .icon-bar {
    margin-bottom: 5px;
    border-radius: 0;
    display: block;
  }

  .main-header .current .icon-bar-1 {
    width: 18px;
  }

  .main-header .current .icon-bar-2 {
    width: 30px;
  }

  .main-header .current .icon-bar-3 {
    width: 10px;
  }

  .main-header .navbar-toggler:hover .icon-bar {
    background-color: #fff;
  }

  .main-header .navbar-toggler:focus .icon-bar {
    background-color: #fff;
  }
  

  /*header ends*/
}
#components-grid-demo-playground [class~='ant-col'] {
  background: transparent;
  border: 0;
}
#components-grid-demo-playground [class~='ant-col'] > div {
  height: 120px;
  font-size: 14px;
  line-height: 120px;
  background: #0092ff;
  border-radius: 4px;
}
#components-grid-demo-playground pre {
  padding: 8px 16px;
  font-size: 13px;
  background: #f9f9f9;
  border-radius: 6px;
}
#components-grid-demo-playground pre.demo-code {
  direction: ltr;
}
#components-grid-demo-playground .ant-col {
  padding: 0;
}
.bgbg{
  background-image: url(https://static.vecteezy.com/system/resources/previews/001/987/560/original/abstract-technology-futuristic-hexagon-overlapping-pattern-with-blue-light-effect-on-dark-blue-background-free-vector.jpg);
  background-size: cover;
  min-height: 600px;
  width: 100%;
}
.setavatar{
  
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-size: cover;
  background-position-x: center;
  background-image: url(https://fikiwiki.com/uploads/posts/2022-02/1645054757_7-fikiwiki-com-p-kartinki-ikonki-7.png);
  text-indent: -99999px; /*Убираем текст, который находится в "value". Это необходимо, если ставите изображение со своим текстом*/
  border: 1px solid rgb(0, 88, 132) /*Делаем чёрную сплошную рамку размером в 1px */
}

.card-container p {
  margin: 0;
}
.card-container > .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -16px;
}
.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 16px;
  background: #fff;
}
.card-container > .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}
.card-container > .ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}
.card-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #fff;
  border-color: #fff;
}
#components-tabs-demo-card-top .code-box-demo {
  padding: 24px;
  overflow: hidden;
  background: #f5f5f5;
}
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -8px;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}
[data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
  background: #000;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  background: #141414;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #141414;
  border-color: #141414;
}
.usercard{
  min-height: 700px;
}
.footer {
  position: absolute;
  left: 0;
  bottom: -35px;
  width: 100%;
  height: 40px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.fix{
  position: sticky;
  top: 0px;

  z-index: 1;
}
.location_text{
  font-size: 16px;
  font-family: revert;
  margin-top: -10px;
}
.table {
	width: 100%;
	margin-bottom: 20px;
	border: 5px solid #fff;
	border-top: 5px solid #fff;
	border-bottom: 3px solid #fff;
	border-collapse: collapse; 
	outline: 3px solid #ffd300;
	font-size: 15px;
	background: #fff!important;
}
.table th {
	font-weight: bold;
	padding: 7px;
	background: #ffd300;
	border: none;
	text-align: left;
	font-size: 15px;
	border-top: 3px solid #fff;
	border-bottom: 3px solid #ffd300;
}
.table td {
	padding: 7px;
	border: none;
	border-top: 3px solid #fff;
	border-bottom: 3px solid #fff;
	font-size: 15px;
}
.table tbody tr:nth-child(even){
	background: #f8f8f8!important;
}
.table tbody tr:nth-child(even):hover{
	background: #aeacac5b!important;
}
/* tile uploaded pictures */
.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 150px;
  /* margin-right: 8px; */
}

.upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
  float: right;
}
.txt-400{
  font-size: 18px;
}
.foot {
  /* position: fixed; */
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
}
.maincon{
  width: 100%;
}
.chatscroll{
  overflow: scroll;
  height: 520px;
}
.ant-image{
  width: 100%;
}
.metro {
  width: 50px;
  height: 230px;
  overflow: scroll;
  border-radius: 50%;
}
.metro2{
  width: 1500px;
  height: 1500px;
  scroll-margin-left: 200px;
}
tr:nth-child(even) {
  background-color: #ff3b3b;
}
/* Нечетные строки */
#table tbody tr:nth-child(odd){
	background: #ffffff;
}
 
/* Четные строки */
#table tbody tr:nth-child(even){
	background: #ffffff;
}
.a1{
  position: absolute;
  margin-top: 70px;
  margin-left: 60px;
  }
  .a1a{
    float: right;
    color: black;
    font-size: 42px;
  }
  .a2{
    position: absolute;
  }
  .a2a{
    font-size: 42px;
    color: black;
    font-family: Freestyle Script;
  }
  .a22{
    position: absolute;
    display: none;
  }
  .ins{
    border: 1px  grey solid ;
    margin-top: 100px;
    max-height: 60%;
    opacity:0.8;
  }
  .a3{
    font-size: 62px;
    color: rgb(0, 0, 0);
    font-family: 'Cinzel', serif;
    text-align: center;
  }
  .a3a{
    border-radius: 50%;
    border: 3px solid red;
  }
  .a4{
    color: black;
    font-size: 12px;
  }
  .a6{
    background-color: white;
  }
  .face{
    color: blue;
    font-size: 22px;
  }
  .face2{
    font-family: Palatino Linotype
  }
  .face3{
    font-size: 12px;
    color: black;
  }
  .ins2{
    border: 1px grey solid;
    margin-top: 20px;
    opacity:0.8;
  }
  .afoot a{
    font-size: 14px;
  }
  .like{
    font-size: 32px;
  
  }.save{
    float: right;
    font-size: 32px;
  }
  .a7{
    font-size: 18px;
  }
  .a8{
    color: grey;
    font-size: 16px;
  }
  .a9{
    color: grey;
    font-size: 12px;
  }
  .h1{
    border: 2px solid grey;
  }
   .rc-anchor-light.rc-anchor-normal, .rc-anchor-light.rc-anchor-compact{
    width: 150px;
   }
   
.hover-effect-btn {
  position: relative;
  width: 100%;
  
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Настройка цвета и прозрачности для блока с заголовком */
  transition: background 0.5s ease;
}
.hover-effect-btn:hover .overlay {
  display: block;
  background: rgba(0, 0, 0, 0.9); /* Настройка цвета и прозрачности для блока с заголовком при наведении */
}
.title {
  position: absolute;
  width: 100%;
  left: 0;
  top: 25%; /* Отступ сверху */
  font-size: 1.900em; /* Размер текста заголовка */
  text-align: center;
  text-transform: uppercase; /* Заглавные буквы */
  color: white; /* Цвет заголовка */
  z-index: 1;
  transition: top .5s ease;
}
.hover-effect-btn:hover .title {
  top: 10%; /* Отступ сверху при наведении */
  text-align: center;
}
.button {
  opacity: 0;
  transition: opacity .35s ease;
}
.button a {
  text-align: center;
  z-index: 1; 
  float: right;
  margin-left: 50px;
}
.hover-effect-btn:hover .button {
  text-align: center;
  opacity: 1;
}
.example-1 .form-group{padding:0;margin:0;}
.example-1 input[type=file]{outline:0;opacity:0;pointer-events:none;user-select:none,;height: 0;}
.example-1 .label{width:100%;border:2px dashed grey;border-radius:5px;display:block;padding:0;transition:border 300ms ease;cursor:pointer;text-align:center}
.example-1 .label i{display:block;font-size:42px;padding-bottom:0;padding: 0;}
.example-1 .label i,.example-1 .label .title{color:grey;transition:200ms color;padding: 0;}
.example-1 .label:hover{border:2px solid #000,}
.example-1 .label:hover i,.example-1 .label:hover .title{color:#000}
.example-1 .label2{width:150px;border:2px dashed grey;border-radius:5px;display:block;padding:0;transition:border 300ms ease;cursor:pointer;text-align:center}

