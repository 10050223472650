/* PasswordReset.css */
.reset-container {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .reset-container h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .input-field {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .input-field:focus {
    border-color: #4c9ef7;
  }
  
  .submit-btn {
    background-color: #4c9ef7;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s ease;
  }
  
  .submit-btn:disabled {
    background-color: #ccc;
  }
  
  .submit-btn:hover:enabled {
    background-color: #3a8cd1;
  }
  
  .error-message {
    color: red;
    margin-top: 15px;
  }
  
  .success-message {
    color: green;
    margin-top: 15px;
  }
  